:root {
  --main-ccc: #263238;

  --purple-50: #f7f9f9;
  --purple-100: #d9e0e3;
  --purple-200: #bbc7cd;
  --purple-300: #9caeb7;
  --purple-400: #7e96a1;
  --purple-500: #607d8b;
  --purple-600: #526a76;
  --purple-700: #435861;
  --purple-800: #35454c;
  --purple-900: #263238;
  //#ffb703

  --purple-800: #35454c10;
  --purple-800: #35454c43;

  --red-50: #f9f7f7;
  --red-100: #f3d9d9;
  --red-200: #ecbcbc;
  --red-300: #e59e9e;
  --red-400: #de8181;
  --red-500: #d96363;
  --red-600: #c75656;
  --red-700: #b34949;
  --red-800: #a03c3c;
  --red-900: #8e3030;
}

.text-red-50 {
  color: var(--red-50);
}

.text-red-100 {
  color: var(--red-100);
}

.text-red-200 {
  color: var(--red-200);
}

.text-red-300 {
  color: var(--red-300);
}

.text-red-400 {
  color: var(--red-400);
}

.text-red-500 {
  color: var(--red-500);
}

.text-red-600 {
  color: var(--red-600);
}

.text-red-700 {
  color: var(--red-700);
}

.text-red-800 {
  color: var(--red-800);
}

.text-red-900 {
  color: var(--red-900);
}

.bg-red-50 {
  background-color: var(--red-50);
}

.bg-red-100 {
  background-color: var(--red-100);
}

.bg-red-200 {
  background-color: var(--red-200);
}

.bg-red-300 {
  background-color: var(--red-300);
}

.bg-red-400 {
  background-color: var(--red-400);
}

.bg-red-500 {
  background-color: var(--red-500);
}

.bg-red-600 {
  background-color: var(--red-600);
}

.bg-red-700 {
  background-color: var(--red-700);
}

.bg-red-800 {
  background-color: var(--red-800);
}

.bg-red-900 {
  background-color: var(--red-900);
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--main-ccc);
  border-radius: 50px;
}

.only-show-notion {
  * {
    padding-inline: 0px !important;
  }
}

// redeploy

.w-500 {
  * {
    width: 500px !important;
  }
}
